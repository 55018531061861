import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';

import { FullViewHeader as Header } from '../Common/FullViewHeader';
import Loading from '../Common/Loading';

import { GET_BILLING_PLANS } from '../../graphql/queries';
import { PlanContainerRow } from '../Common/styled/PlanDiv';
import { PublicPlanViewDiv } from './PlanLabel';

export const PlansPublicView = () => {
  const { t } = useTranslation();
  navigate('/dashboard');

  const { loading: loadingPlans, data: dataPlans } = useQuery(GET_BILLING_PLANS);

  if (loadingPlans) return <Loading />;

  return (
    <div>
      <Header />
      <h2 className="text-center">{t('choose.a.plan.that.works.for.you')}</h2>
      <PlanContainerRow className="mt-4">
        {dataPlans && dataPlans.billingPlans && dataPlans.billingPlans.map((billingPlan, i) => (billingPlan.hideInDefaultView ? null : (
          <PublicPlanViewDiv billingPlan={billingPlan} key={i.toString()} />
        )))}
      </PlanContainerRow>
      <div className="text-center mt-5">
        <b>{`${t('need.a.bigger.solution')}? `}</b>
        <a href="https://alma-medical.com/en/contact/" target="blank">{`${t('contact.the.sales.team')}.`}</a>
      </div>
    </div>
  );
};
