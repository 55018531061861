import React from 'react';
import '../../i18n';

import Seo from '../../components/Seo';
import { AppContainer } from '../../components/Common/styled/AppContainer';
import GlobalStyle from '../../themes/GlobalStyle';
import GlobalThemeStyles from '../../themes/GlobalThemeStyles';
import { EmptyPageModern } from '../../components/Common/styled/EmptyPage';
import { PlansPublicView } from '../../components/Plan/PlansPublicView';

export default () => (
  <AppContainer>
    <Seo />
    <GlobalStyle />
    <GlobalThemeStyles />
    <EmptyPageModern big>
      <PlansPublicView />
    </EmptyPageModern>
  </AppContainer>
);
